<template>
<vs-row vs-type="flex" vs-justify="center">
    <div class="flex flex-wrap">
        <div class="btn-group mb-base">
            <vs-button type="filled" class="text-dark" color="#e5e5e5" @click="handlePrevious(1)">Previous</vs-button>
            <vs-button :type="page.type" :color="page.color" :class="page.textColor" v-for="(page, i) in pages" @click="handleSelected(page.weekDate)" :key="i">{{ page.period }}</vs-button>
            <vs-button type="filled" class="text-dark" color="#e5e5e5" @click="handleNext(1)">Next</vs-button>
        </div>
    </div>
</vs-row>
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
            pages: [],
            startDay: 0,
            currentDate: undefined
        };
    },
    created() {
        this.currentDate = new moment();
        var day = moment().day();
        this.startDay = day - 3;
        this.createWeeks();
    },
    methods: {
        createWeeks() {
            this.pages = [];

            let i = 0 ;
            const startWeekDay = new moment(this.currentDate)
                    .day(this.startDay)
                    .toDate();
              const endWeekDay = new moment(startWeekDay).add(7, "d");

              for (var m = moment(startWeekDay); m.isBefore(endWeekDay); m.add(1, 'days')) {
                  const start = moment(m).format("D MMM");
                    const page = {
                    period: `${start}`,
                    color: "#e5e5e5",
                    textColor: "text-dark",
                    type: "filled",
                    weekDate: m.format('YYYY-MM-DD')
                };
                    if (i === 3) {
                    const start = moment(m).format("ddd, MMM D (YYYY)");
                    const end = moment(endWeekDay).format("ddd, MMM D (YYYY)");

                      const ss = moment(m).format("yy-MM-D");
                      this.$emit("searchData", ss);
                      page.period = `${start}`;
                      page.color = "dark";
                      page.type = "filled";
                      page.textColor = "";
                  }
                this.pages.push(page);
                i++;
              }
        },
        handleSelected(value) {
            var day = moment(value).day();
            this.startDay = day - 3;
            this.currentDate = value;
            this.createWeeks();
        },
        handlePrevious(day) {
            this.startDay = this.startDay - day;
            this.createWeeks();
        },
        handleNext(day) {
            this.startDay = this.startDay + day;
            this.createWeeks();
        }
    }
};
</script>
