<template>
<div class="vx-row">
    <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <vx-card title="Filter">
            <div class="filter-container">
                <h6 class="font-bold mb-3">Start Date</h6>
                <div>
                    <flat-pickr v-model="fromDate" placeholder="From Date" class="w-full" />
                </div>
                <vs-divider />
                <h6 class="font-bold mb-4">End Date</h6>
                <div>
                    <flat-pickr v-model="toDate" placeholder="To Date" class="w-full" />
                </div>
                <vs-divider />
                <div class="flex">
                    <vs-button color="success" @click="downloadexcel">Export</vs-button>
                </div>
            </div>
        </vx-card>
    </div>
    <div class="vx-col md:w-3/4 sm:w-1/2 w-full">
        <vx-card title="Visit History">
            <ButtonDayComponent @searchData="loadData" @searchByPage="searchByPage"></ButtonDayComponent>
            <vs-table stripe :data="parkingData" v-if="parkingData.length">
                <template #thead>
                    <vs-th>Modal</vs-th>
                    <vs-th>Entry Date</vs-th>
                    <vs-th class="width30">Result</vs-th>
                </template>
                <template :slot-scope="{data}">
                    <vs-tr v-for="(tr, index) in parkingData" :key="index">
                        <vs-td>{{tr.carModel}} ({{tr.registrationPlate}})
                        </vs-td>
                        <vs-td>{{tr.entryDateTime | formatLongDateTime }}</vs-td>
                        <vs-td>
                            <vs-row class="mb-2">
                                <vs-chip color="success" v-if="tr.entryResult==='Entry Allowed'">{{ tr.entryResult }}</vs-chip>
                                <vs-chip color="danger" v-if="tr.entryResult==='Entry Denied'">{{ tr.entryResult }}</vs-chip>
                            </vs-row>
                            <span>{{ tr.resultDetail }}</span>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
        </vx-card>
    </div>
</div>
</template>

<script>
import {
    visitHistoryData
} from "../../store/api/visitHistory";
import ButtonDayComponent from "@/components/ButtonDayComponent";
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
    reportsData
} from "@/store/api/report";

export default {
    data() {
        return {
            parkingData: [],
            noData: {
                title: "No Records Found",
            }
        }
    },
    components: {
        ButtonDayComponent,
        flatPickr,
    },
    async created() {
        this.fromDate = moment().format();
        this.toDate = moment().format();
    },
    methods: {
        async loadData(date) {
            this.parkingData = await visitHistoryData.SearchUserHistoryByDate(date);
        },
        searchByPage() {},
        async downloadexcel() {
            const reportParameter = {
                startDate: this.fromDate,
                endDate: this.toDate,
                productId: this.selectedProduct
            };

            let data = await reportsData.visitHistoryReportExportToExcel(reportParameter);
            console.log("🚀 ~ file: VisitHistory.vue ~ line 88 ~ downloadexcel ~ data", data)
            if (!data.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: data.message,
                    color: "warning",
                    position: "top-center"
                });
                return;
            }
            window.open(data.data.url, '_blank').focus();
        },
    }
}
</script>
